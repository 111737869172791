import React from 'react'
import Layout from '../layouts'

const StockPhotography = () => (
  <Layout>
    <section>
      <h1>Stock Photography</h1>
      <article>
        <p>
          Sue has built an extensive image library over the last 25 years from
          all over the world, including rare animal behaviour (killer whales
          hunting gray whales, polar bears hunting beluga whales), a beautiful
          portfolio of emperor penguins, people, wildlife and scenery in the
          Arctic and Antarctic, and travel imagery from Botswana, Zambia,
          Rwanda, South Africa, Japan, Kamchatka, The Kurils, the South Pacific,
          Vietnam, Costa Rica, Nicaragua and Argentina, to name but a few!
        </p>

        <p>Sue's stills are handled by:</p>
        <h3>Alamy</h3>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.alamy.com/search.html?CreativeOn=1&adv=1&ag=0&all=1&creative=&et=0x000000000000000000000&vp=0&loc=0&qt=&qn=Sue%20Flood&lic=6&lic=1&imgt=0&archive=1&dtfr=&dtto=&hc=&selectdate=&size=0xFF&aqt=&epqt=&oqt=&nqt=&gtype=0&pn=1&ps=100&qt_raw=#BHM=foo=bar&st=0&pn=1&ps=100&sortby=2&qt=&qt_raw=&qn=Sue%2520Flood&lic=3&edrf=0&mr=0&pr=0&aoa=1&creative=&videos=&nu=&ccc=&bespoke=&apalib=&ag=0&hc=0&et=0x000000000000000000000&vp=0&loc=0&ot=0&imgt=0&dtfr=&dtto=&size=0xFF&blackwhite=&cutout=&archive=1&name=&groupid=&pseudoid=&userid=&id=&a=&xstx=0&cbstore=1&resultview=sortbyPopular&lightbox=&gname=&gtype=&apalic=&tbar=1&pc=&simid=&cap=1&customgeoip=CA&vd=0&cid=&pe=&so=&lb=&fi=0&langcode=en&upl=0&cufr=&cuto=&howler=&cvrem=0&cvtype=0&cvloc=0&cl=0&upfr=&upto=&primcat=&seccat=&cvcategory=*&restriction=&random=&ispremium=1&flip=0&saveQry=&editorial=1&t=0&edoptin="
          >
            www.alamy.com
          </a>
        </p>
        <h3>Nature Picture Library</h3>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.naturepl.com/-photographers/e-i/flood-sue.html"
          >
            www.naturepl.com
          </a>
        </p>
      </article>
    </section>
  </Layout>
)

export default StockPhotography
